import React from "react";
// import "./assets/css/custom.css";
// import "./assets/css/main.css";

const PrivacyPage = ({url}) => {
    const hostedUrl = `${url}/static`;
    const staticHTML = `
    <body>
        <div class="navbar w-nav" id="myHeader">
            <div class="container w-container">
                <div class="grid-navbar">
                    <a href="/" 
                        class="brand w-nav-brand" aria-label="home">
                        <img
                            src="${hostedUrl}/assets/img/GSPro-Logo.svg"
                            loading="eager" alt="" class="logo"></a>
                            <nav role="navigation" 
                            class="nav-menu w-nav-menu">
                            <a href="/" aria-current="page"
                            class="nav-link w-nav-link" >Home</a>
                            <a href="/about" aria-current="page" class="nav-link w-nav-link" >About</a>
                            <a href="/features" class="nav-link w-nav-link" >Features</a>
                            <a href="/pricings" class="nav-link w-nav-link" >Pricing</a>
                            <a href="/contact" class="nav-link w-nav-link" >Contact</a>
                            <a href="/login" class="nav-link m-nav-link">Login</a>
                        </nav>
                    <div class="navbar-right nav-hide">
                        <a href="/login" class="nav-link">Login</a>
                        <a data-w-id="f3952b50-6d1f-393e-b11f-b676ceacc841" href="/registration"
                            class="button-link-icon nav-button-hide w-inline-block">
                            <div>
                                <input type="submit" value="Try For Free" data-wait="Please wait..." class="button-primary-1 button-subscribe w-button">
                            </div>
                        </a>
                    </div>
                    <div  class="menu-button w-nav-button">
                        <div class="w-icon-nav-menu"></div>
                    </div>
                </div>
            </div>
            <div class="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0"></div>
        </div>
        <section class="hero-section">
            <div class="hero-section-decoration innerpage">
                <div class="container-medium w-container">
                    <div class="hero-inner-content-wrap">
                        <div class="hero-inner-content">
                            
                            <h1>Privacy</h1>
                            <p class="benefit-content">
                                Last updated: April 05, 2023
                            </p>
                            <div class="rich-text w-richtext" style="text-align: left;">
                                <h5>Welcome</h5>
                                <p class="blacktext">Thank you for choosing Gallery Studio Pro. Your privacy and the security of your personal information are of utmost importance to us. This Privacy Policy outlines how we collect, use, disclose, and protect your data when you use our platform to manage your media gallery, obtain approvals, and collaborate with comments.</p>

    <p class="blacktext">By using Gallery Studio Pro, you agree to the practices described in this Privacy Policy. If you do not agree with these practices, please do not use our services.</p>

    <h5>1. Information We Collect</h5>

    <p class="blacktext">1.1 Account Information: When you create an account, we collect personal information such as your name, email address, and password.</p>

    <p class="blacktext">1.2 Media Files: We store the media files you upload to our platform securely. These may include images, videos, documents, and other media types.</p>

    <p class="blacktext">1.3 Usage Data: We collect data about how you interact with our platform, including your actions within the application, IP address, device type, and browser information.</p>

    <p class="blacktext">1.4 Comments and Collaborative Data: Comments and annotations made on media files are stored to facilitate collaboration. These comments may contain user-generated content.</p>

    <h5>2. How We Use Your Information</h5>

    <p class="blacktext">We use the collected information for the following purposes:</p>

    <p class="blacktext">2.1 Provide and Improve Services: To provide and enhance the functionality of Gallery Studio Pro, including media gallery management, approvals, and collaborative commenting.</p>

    <p class="blacktext">2.2 Communication: To communicate with you about updates, new features, and important information related to our services.</p>

    <p class="blacktext">2.3 Security: To maintain the security and integrity of our platform, including protecting against unauthorized access, fraud, and abuse.</p>

    <p class="blacktext">2.4 Legal Compliance: To comply with legal obligations and respond to law enforcement requests and legal processes.</p>

    <h5>3. Sharing Your Information</h5>

    <p class="blacktext">We do not sell or rent your personal information to third parties. However, we may share your information in the following circumstances:</p>

    <p class="blacktext">3.1 Service Providers: We may share your information with third-party service providers who assist us in operating our platform and providing our services. These providers are bound by contractual obligations to protect your data.</p>

    <p class="blacktext">3.2 Legal Obligations: We may disclose your information to comply with applicable laws, regulations, legal processes, or government requests.</p>

    <p class="blacktext">3.3 Business Transactions: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred to the acquiring entity.</p>

    <h5>4. Data Security</h5>

    <p class="blacktext">We take the security of your data seriously and implement industry-standard security measures to protect it from unauthorized access, disclosure, alteration, or destruction.</p>

    <h5>5. Your Choices</h5>

    <p class="blacktext">You have the following rights regarding your personal information: Access: You can request access to the personal information we hold about you. Correction: You can request corrections to inaccurate or incomplete information. Deletion: You can request the deletion of your account and associated data, subject to legal obligations. Opt-Out: You can opt out of marketing communications by following the instructions in our emails.</p>

    <h5>6. Changes to this Privacy Policy</h5>

    <p class="blacktext">We may update this Privacy Policy from time to time to reflect changes in our practices or for legal reasons. We will notify you of any significant changes through our platform or other means.</p>


                                <p class="blacktext">&zwj;</p>
                                <h5>Contact Us</h5>
                                <p class="blacktext"><strong>&zwj;</strong>Please send your feedback, comments, requests for technical support:</p>
                                <p class="blacktext"><a href="#" target="_blank">info@gallerystudiopro.com</a></p>
                            </div>
                        </div>
                    </div>
                    
            </div>
            
            </div>

        </section>


    <section class="getstarted">
        <div class="container w-container">
            <div class="section-title text-center m-b-0">
                <img src="${hostedUrl}/assets/img/FavIcon.png" class="getstartedLogo"/>
                <h2 class="no-margin">Get Started Today</h2>
                <div class="w-flex w-col-8 text-center xy-auto m-t-10p text-primary-2">
                    <div class="w-col-4 w-flex w-getstarted-checks">
                        <img src="${hostedUrl}/assets/img/Check.svg" />
                        <span class="f18">Free & Paid Plans</span>
                    </div>
                    <div class="w-col-4 w-flex w-getstarted-checks">
                        <img src="${hostedUrl}/assets/img/Check.svg" />
                        <span class="f18">Easy Payment Options</span>
                    </div>
                    <div class="w-col-4 w-flex w-getstarted-checks">
                        <img src="${hostedUrl}/assets/img/Check.svg" />
                        <span class="f18">Cancel Anytime</span>
                    </div>
                </div>
                <div class="topbannerLogo">
                    <input type="submit" value="Try GSPRO For Free" data-wait="Please wait..." class="button-primary-1 button-subscribe w-button m-t-10p">
                </div>
                <div class="getstartedimage">
                    <img src="${hostedUrl}/assets/img/Footerimage.png" />
                </div>
            </div>
        </div>
    </section>
        <footer class="footer">
            <div class="container w-container">
                <div class="w-layout-grid grid-footer">
                    <div>
                        <a href="/"
                            aria-current="page" class="footer-logo-wrap w-inline-block w--current">
                            <img src="${hostedUrl}/assets/img/FavIcon.png" loading="eager" alt="" class="footer-logo-wrap">
                        </a>
                        <p class="no-margin blacktext">Securely Organize, Showcase,and Collaborate 
                            On your Media Assets with <b>Gallery Studio pro</b></p>
                    </div>
                    <div id="w-node-_5b5b05ab-e775-238d-7db9-2056f436ada0-f436ad98" class="footer-item-wrap">
                        <h6 class="footer-title">Pages</h6>
                        <div class="w-layout-grid grid-footer-link">
                            <div class="footer-link-wrap">
                                <a href="/" aria-current="page"
                                class="footer-link w--current">Home</a>
                                <a href="/about" aria-current="page"
                                class="footer-link w--current">About</a>
                                <a href="/features" aria-current="page"
                                class="footer-link w--current">Features</a>
                                <a href="/pricings" aria-current="page"
                                class="footer-link w--current">Pricing</a>
                                <a href="/contact" aria-current="page"
                                class="footer-link w--current">Contact</a>
                            </div>
                        </div>
                    </div>
                    <div id="w-node-_5b5b05ab-e775-238d-7db9-2056f436adb6-f436ad98" class="footer-item-wrap">
                        <h6 class="footer-title">Utility pages</h6>
                        <div class="w-layout-grid grid-footer-link">
                            <div class="footer-link-wrap">
                                <a href="privacy" class="footer-link"> Privacy</a>
                                <a href="terms" class="footer-link">Terms</a>
                                <a href="security" class="footer-link">Security</a>
                            </div>
                        </div>
                    </div>
                    <div id="w-node-_5b5b05ab-e775-238d-7db9-2056f436adcc-f436ad98" class="footer-item-wrap">
                        <h6 class="footer-title">Contact Us</h6>
                        <div class="grid-footer-link"><a href="mailto:info@gallerystudiopro.com"
                                class="footer-link">info@gallerystudiopro.com</a>
                            <div class="footer-social-wrap">
                                <a href="" target="_blank"
                                    class="footer-social-link  w-inline-block"><img
                                        src="${hostedUrl}/assets/img/fb.svg"
                                        loading="eager" alt="" class="footer-social-icon"></a>
                                        <a href=""
                                    target="_blank" class="footer-social-link w-inline-block"><img
                                        src="${hostedUrl}/assets/img/insta.svg"
                                        loading="eager" alt="" class="footer-social-icon"></a>
                                        <a
                                    href="" target="_blank"
                                    class="footer-social-link w-inline-block"><img
                                        src="${hostedUrl}/assets/img/youtube.svg"
                                        loading="eager" alt="" class="footer-social-icon"></a>
                                    
                                    </div>
                        </div>
                    </div>
                </div>
                <div class="footer-bottom text-center ">
                    <div class="xy-auto"> Powered by <a href=""
                            target="_blank" class="footer-link text-primary-2">The Smart Trunk</a></div>
                </div>
            </div>
        </footer>    
    </body>
    `;
    return <div dangerouslySetInnerHTML={{ __html: staticHTML }} />;
}

export default PrivacyPage;