import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import Home from "./pages/Home/Home";
import Login from "./pages/Authentication/Login";
import Registration from "./pages/Authentication/Registration";
import ForgotPassword from "./pages/Authentication/ForgotPassword";
import NewPassword from "./pages/Authentication/NewPassword";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Profile from "./pages/Home/Profile";
import Pricing from "./pages/Home/Pricing";
import PaymentSuccessfull from "./pages/Home/PaymentSuccessfull";
import Error500 from "./pages/Home/Error500";
import Error404 from "./pages/Home/Error404";
import ClientLogin from "./pages/Clients/ClientLogin";
import ClientDashboard from "./pages/Clients/ClientDashboard";
import ClientInsideFolder from "./pages/Clients/ClientInsideFolder";
import AdminUserList from "./pages/Admin/AdminUserList";
import AdminLogin from "./pages/AdminDashboard/AdminLogin/AdminLogin";
import AdminDashboard from "./pages/AdminDashboard/AdminDashboard";
import AdminDashboardHome from "./pages/Admin/AdminDashboardHome";
import AdminProtectedRoutes from "./pages/ProtectedRoutes/AdminProtectedRoutes";
import UserProtectedRoutes from "./pages/ProtectedRoutes/UserProtectedRoutes";
import ClientProtectedRoutes from "./pages/ProtectedRoutes/ClientProtectedRoutes";
import ClientDashboardImg from "./pages/ClientDashboardImage/ClientDashboardImg";
import ClientFolderDetails from "./pages/Clients/ClientFolderDetails";
import toast, { Toaster } from "react-hot-toast";
import LandingPage from "./pages/staticPages/LandingPage";
import AboutPage from "./pages/staticPages/AboutPage";
import ContactPage from "./pages/staticPages/ContactPage";
import FeaturesPage from "./pages/staticPages/FeaturesPage";
import PricingPage from "./pages/staticPages/PricingPage";
import SecurityPage from "./pages/staticPages/SecurityPage";
import PrivacyPage from "./pages/staticPages/PrivacyPage";
import TermsPage from "./pages/staticPages/TermsPage";
import LoaderScreen from "./components/LoaderScreen";
import { LoadingContext } from "./pages/Home/ContextProvider";
import UploadinModal from "./components/Sidebar/UploadinModal";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { backend_api } from "./redux/store";

function App() {
  const [loadingCount, setLoadingCount] = useState(0);
  
  const increaseLoadingCounter = () => {
    setLoadingCount((prevCount) => prevCount + 1);
  }
  const decreaseLoadingCounter = () => {
    setLoadingCount((prevCount) => Math.max(prevCount - 1, 0));
  }
  const [isUploadingStarted, setIsUploadingStarted] = useState(false);

  const increaseUploadingLoadingCounter = () => {
    setIsUploadingStarted(true);
  }
  const decreaseUploadingLoadingCounter = () => {
    setIsUploadingStarted(false);
  }

  return (
    <LoadingContext.Provider value={{ increaseLoadingCounter, decreaseLoadingCounter,isUploadingStarted,increaseUploadingLoadingCounter,decreaseUploadingLoadingCounter }}>
      <BrowserRouter>
        <Toaster toastOptions={toastOptions} />
        <LoaderScreen show={loadingCount > 0} />
        <Routes>
          {/* Add a default route with a component */}
          <Route
            path="/:component"
            element={<UserProtectedRoutes Component={Home} />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/passwordReset/:token/:id" element={<NewPassword />} />
          <Route path="/Clientlogin" element={<ClientLogin />} />
          <Route
            path="/Clientdashboard"
            element={<ClientProtectedRoutes Component={ClientDashboard} />}
          />
          <Route
            path="/ClientInsideFolder"
            element={<ClientProtectedRoutes Component={ClientInsideFolder} />}
          />
          {/* <Route
            path="/dashboard"
            element={<UserProtectedRoutes Component={Dashboard} />}
          /> */}
          {/* <Route
            path="/Clientdashboard/image"
            element={<ClientProtectedRoutes Component={ClientDashboardImg} />}
          /> */}
          {/* <Route
            path="/Clientdashboard/folder"
            element={<ClientProtectedRoutes Component={ClientFolderDetails} />}
          /> */}
          <Route
            path="/profile"
            element={<UserProtectedRoutes Component={Profile} />}
          />
          <Route
            path="/Pricing"
            element={<UserProtectedRoutes Component={Pricing} />}
          />
          <Route path="/paymentSuccessfull" element={<UserProtectedRoutes Component={PaymentSuccessfull} />} />
          <Route path="/Error500" element={<Error500 />} />
          <Route path="/admin-login" element={<AdminLogin />} />
          <Route
            path="/admin-dashboard"
            element={<AdminProtectedRoutes Component={AdminDashboard} />}
          />
          <Route
            path="/admin/user-details"
            element={<AdminProtectedRoutes Component={AdminDashboard} />}
          />
          
          <Route path="/" element={<LandingPage url={backend_api} />} />
          <Route path="/about" element={<AboutPage url={backend_api} />} />
          <Route path="/contact" element={<ContactPage url={backend_api} />} />
          <Route path="/features" element={<FeaturesPage url={backend_api} />} />
          <Route path="/pricings" element={<PricingPage url={backend_api} />} />
          <Route path="/security" element={<SecurityPage url={backend_api} />} />
          <Route path="/privacy" element={<PrivacyPage url={backend_api} />} />
          <Route path="/terms" element={<TermsPage url={backend_api} />} />
          <Route path="/error404" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
    </LoadingContext.Provider>
  );
}

export default App;

const toastOptions = { 
  style: { 
    borderRadius: '1.5rem',
    color: '#fff',
  }, 
  success: { 
    style: { 
      background: '#5DCE7B'
    }, 
    icon: <CheckRoundedIcon size={10} />, 
    iconTheme: { 
      primary: '#5DCE7B',
      secondary: '#fff' 
    }
  },
  error: { 
    style: { 
      background: '#FA6E5A' 
    }, 
    icon: <CloseRoundedIcon size={10} />, 
    iconTheme: { 
      primary: '#FA6E5A', 
      secondary: '#fff' 
    }
  }
};
