import React, { useContext } from "react";
import "./headerStyles.css";
import logo from "../../assets/images/GSPro-Logo.svg";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import User from "../../assets/images/Group3.png";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getPlanSettings, getUserDetails } from "../../redux/actions/userAction";
import { LoadingContext } from "../../pages/Home/ContextProvider";

const Header = () => {
  const { increaseLoadingCounter, decreaseLoadingCounter } = useContext(LoadingContext);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    Cookies.remove("access_token");
    Cookies.remove("userId");
    Cookies.remove("client_access_token");
    handleClose();
    window.location.reload();
  };

  useEffect(() => {
    increaseLoadingCounter();
    dispatch(getUserDetails()).then(() => {
      decreaseLoadingCounter();
    });
    increaseLoadingCounter();
    dispatch(getPlanSettings()).then(() => {
      decreaseLoadingCounter()
    });
  }, []);

  return (
    <div className="header">
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          style: {
            borderRadius: 10, // Adjust the value as needed
          },
        }}

      >
        <MenuItem onClick={() => navigate("/profile")}
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            paddingLeft:'20px',
            paddingRight:'20px',
            width:'200px'
          }}>
          <span
            style={{
              backgroundColor: "#5DCE7B",
              padding: "5px",
              paddingLeft: "23%",
              paddingRight: "23%",
              borderRadius: "20px",
              color:'#fff',
            }}
          >
           View Profile
          </span>
        </MenuItem>
        <MenuItem
          onClick={handleLogout}
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            paddingLeft:'20px',
            paddingRight:'20px'
          }}
        >
          <span
            style={{
              backgroundColor: "#FA6E5A",
              padding: "5px",
              paddingLeft: "35%",
              paddingRight: "35%",
              borderRadius: "20px",
              color:'#fff'
            }}
          >
            Logout
          </span>
        </MenuItem>
      </Menu>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <img
          src={logo}
          alt="google"
          style={{
            height: "3rem",
          }}
        />
      </div>
      <div className="header-title-btns-container">
        <div className="header-profileName">
          <p id="welcome-text">
            Welcome Back! <span id="welcome-username">{user?.name}</span>
          </p>
        </div>
        <div className="user-icon"
          style={{
            marginRight: "20px",
            display: "flex",
            gap: "15px",
          }}
        >
          {/* <button className="header-btn">
            <img
              id="header-btn-icon"
              src="https://img.icons8.com/fluency-systems-regular/48/A0A3B1/crescent-moon.png"
              alt="crescent-moon"
            />
          </button> */}
          <button onClick={handleClick} className="header-btn">
            <img
              id="header-btn-icon"
              src="https://img.icons8.com/fluency-systems-regular/48/A0A3B1/user--v1.png"
              alt="night-mode"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
