import React, { useState} from "react";
import "./assets/css/custom.css";
import "./assets/css/main.css";
import mainLogo from "./assets/img/GSPro-Logo.svg";
import BannerVideo from "./assets/img/WebsiteVideo.mp4";
import star from "./assets/img/star.svg";
import img9 from "./assets/img/image9.png";
import img10 from "./assets/img/image10.png";
import img11 from "./assets/img/image11.png";
import img12 from "./assets/img/image12.png";
import img14 from "./assets/img/image14.png";
import img15 from "./assets/img/image15.png";
import Shld from "./assets/img/Shld1.png";
import frameImg from "./assets/img/Frame.svg";
import frameImg1 from "./assets/img/Frame-1.svg";
import frameImg2 from "./assets/img/Frame-2.svg";
import frameImg3 from "./assets/img/Frame-3.svg";
import frameImg4 from "./assets/img/Frame-4.svg";
import frameImg5 from "./assets/img/Frame-5.svg";

import IconAudio from "./assets/img/IconAudio.png";
import IconVideo from "./assets/img/IconVideo.png";
import IconImage from "./assets/img/IconImage.png";

// import "./assets/js/main.js";
// import "./assets/js/ani.js";
// import "./assets/js/gsap-latest.min.js";
// import "./assets/js/ScrollTrigger.min.js";

// Import Header footer 


import StaticHeader from "./comps/StaticHeader";
import StaticFooter from "./comps/StaticFooter";
import TryBanner from "./comps/TryBanner";

const LandingPage = () => {

   
    return (
        <div>
          
          <div id="mainContent" >
            <div className="navbar w-nav" id="myHeader">
              <StaticHeader />
              <div className="w-nav-overlay" data-wf-ignore id="w-nav-overlay-0" />
            </div>
            <section className="hero-section">
              <div className="hero-section-decoration">
                <div className="container-medium w-container gs_reveal homebanner">
                  <h1>Securely Organize, Showcase, <br />
                    and Collaborate</h1>
                  <h3 className="hero-content-center">On your Media Assets with <span className="text-primary-1">Gallery Studio pro</span></h3>
                  <div className="text-center max-width">
                  <a href="/login">
                  <div className="button-primary-1 button-subscribe w-button" style={{display: 'inline-flex'}} >Try GSPRO For Free</div>
                  </a>
                  </div>
                  <div className="hero-image-center">
                    {/* <img
                          src="/assets/img/HeroBanner.png"
                          loading="eager" alt=""
                          sizes="(max-width: 767px) 92vw, (max-width: 991px) 94vw, (max-width: 1279px) 95vw, 1140.0115966796875px"> */}
                    <div style={{marginTop: '40px', position:"relative"}}>
                      <video style={{maxWidth: '100%'}} autoPlay loop muted playsInline disablePictureInPicture={true}>
                        <source src={BannerVideo} />
                      </video>
                      <div className="BannerIcons">
                      <img src={IconImage} className="home-icon-audio" />
                      <img src={IconVideo} className="home-icon-audio" />
                      <img src={IconAudio} className="home-icon-audio" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="audience">
              <div className="container w-container">
                <div className="section-title text-center">
                  <div className="pre-title-inline gs_reveal">
                    <img src={star} loading="lazy" alt="" className="pre-title-image" />
                    <div>Audience</div>
                  </div>
                  <span className="f24 text-primary-1 gs_reveal">Gallery Studio pro</span>
                  <h2 className="no-margin gs_reveal">Caters to a wide audience </h2>
                </div>
                <div className="cat-audience w-flex w-f-start w-f-wrap">
                  <div className="w-col-5 gs_reveal">
                    <div className="cta-section one">
                      <img src={img9} alt="Photography" />
                      <span className="f20">Photography</span>
                    </div>
                  </div>
                  <div className="w-col-7 w-flex w-f-wrap ">
                    <div className="w-col-12 w-flex gs_reveal">
                      <div className="cta-section two">
                        <img src={img10} alt="Interors" />
                        <span className="f20">interior design</span>
                      </div>
                      <div className="cta-section three gs_reveal">
                        <img src={img11} alt="3D Render" />
                        <span className="f20">3D Render</span>
                      </div>
                    </div>
                    <div className="cta-section four gs_reveal">
                      <img src={img12} alt="Real Estate" />
                      <span className="f20">Real Estate </span>
                    </div>
                  </div>
                  <div className="w-col-12 w-flex last-tiles">
                    <div className="w-col-4 gs_reveal">
                      <div className="cta-section five">
                        <img src={img14} alt="Art" />
                        <span className="f20">Art</span>
                      </div>
                    </div>
                    <div className="w-col-4 gs_reveal">
                      <div className="cta-section six">
                        <img src={img15} alt="Video Production" />
                        <span className="f20">Video Production</span>
                      </div>
                    </div>
                    <div className="w-col-4 last-tile gs_reveal">
                      <div className="cta-section seven text-center">
                        <div className>
                          In any Creative industry, 
                          <span className="f30 text-secondary-3">Securely Share</span>
                          your media files
                        </div>
                        <img src={Shld} alt="others" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="features">
              <div className="container w-container">
                <div className="section-title text-center gs_reveal">
                  <div className="pre-title-inline">
                    <img src={star} loading="lazy" alt="" className="pre-title-image" />
                    <div>Features</div>
                  </div>
                  <h2 className="no-margin text-white">Unlock the powers</h2>
                  <span className="f24 text-white">of secure media gallery management</span>
                </div>
                <div className="w-flex w-f-start w-f-wrap">
                  <div className="w-col-4 text-center gs_reveal">
                    <div className="w-flex w-flex-coloum text-center text-white w-features w-col-8 xy-auto">
                      <img src={frameImg} alt="others" className="w-tile-icon" />
                      <div className="w-flex w-flex-coloum">
                        <span className="f24 m-t-10p">Unique URL</span>
                        <span className="m-t-10p">Each customer URL is unique to protect the media</span>
                      </div>
                    </div>
                  </div>
                  <div className="w-col-4 text-center gs_reveal">
                    <div className="w-flex w-flex-coloum text-center text-white w-features w-col-8 xy-auto">
                      <img src={frameImg1} alt="others" className="w-tile-icon" />
                      <div className="w-flex w-flex-coloum">
                        <span className="f24 m-t-10p">Security &amp; Privacy</span>
                        <span className="m-t-10p">Share your work with your client with a password protected</span>
                      </div>
                    </div>
                  </div>
                  <div className="w-col-4 text-center gs_reveal">
                    <div className="w-flex w-flex-coloum text-center text-white w-features w-col-8 xy-auto">
                      <img src={frameImg2} alt="others" className="w-tile-icon" />
                      <div className="w-flex w-flex-coloum">
                        <span className="f24 m-t-10p">Reviews</span>
                        <span className="m-t-10p">Multiple review options for you client activities</span>
                      </div>
                    </div>
                  </div>
                  <div className="w-col-4 text-center gs_reveal">
                    <div className="w-flex w-flex-coloum text-center text-white w-features w-col-8 xy-auto">
                      <img src={frameImg3} alt="others" className="w-tile-icon" />
                      <div className="w-flex w-flex-coloum">
                        <span className="f24 m-t-10p">Simple Interface</span>
                        <span className="m-t-10p">Our interface is as simple as using google drive</span>
                      </div>
                    </div>
                  </div>
                  <div className="w-col-4 text-center gs_reveal">
                    <div className="w-flex w-flex-coloum text-center text-white w-features w-col-8 xy-auto">
                      <img src={frameImg4} alt="others" className="w-tile-icon" />
                      <div className="w-flex w-flex-coloum">
                        <span className="f24 m-t-10p">Clients Management</span>
                        <span className="m-t-10p">You can Create Multiple client profiles and make them active / inactive</span>
                      </div>
                    </div>
                  </div>
                  <div className="w-col-4 text-center gs_reveal">
                    <div className="w-flex w-flex-coloum text-center text-white w-features w-col-8 xy-auto">
                      <img src={frameImg5} alt="others" className="w-tile-icon" />
                      <div className="w-flex w-flex-coloum">
                        <span className="f24 m-t-10p">Activity Tracker</span>
                        <span className="m-t-10p">Track your client activities of login and others </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="getstarted">
              <TryBanner />
            </section>
            <footer className="footer">
              <StaticFooter />
            </footer>
          </div>
          {/* Adding JavaScript code */}
        </div>
      );
}

export default LandingPage;