import React, { useState, useEffect, useContext } from "react";
import "./clientDetailsStyles.css";
import BackButton from "../../components/BackButton";
import { useLocation, useNavigate } from "react-router-dom";
import noFiles from "../../assets/images/noFiles.png";
import emptyFiles from "../../assets/images/emptyfiles.png";
import Folderpng from "../../assets/images/Vector(10).png";
import UpArrow from "../../assets/images/Vector(19).png";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ImageGallery from "../../components/ImageGallery/ImageGallery";
import moment from "moment";
import Box from "@mui/material/Box";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
// import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button } from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'; import {
  UserDeleteCustomer,
  UserUpdateCustomerStatus,
  createFolder,
  deleteFolder,
  getCustumerDetails,
  getFolderFromTrash,
  getUserDetails,
  moveFolderToTrash,
  restoreFolder,
  uploadFile,
  uploadFolder,
} from "../../redux/actions/userAction";
import { useSelector, useDispatch } from "react-redux";
import { getFolder } from "../../redux/actions/userAction";
import axios from "axios";
import JSZip from "jszip";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
// import throttle from "lodash.throttle";
import { saveAs } from "file-saver";
import { downloadDeleted } from "../../redux/actions/userAction";
// import { saveAs } from "file-saver";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Cookies from "js-cookie";
import Switch from "react-switch";
import commments from "../../assets/images/comments.png";
import viewall from "../../assets/images/viewall.png";
import important from "../../assets/images/important.png";
import approved from "../../assets/images/approved.png";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  Delete,
  DeleteForever,
  RemoveRedEyeOutlined,
  RestoreFromTrash,
} from "@mui/icons-material";
import { backend_api } from "../../redux/store";
import toast from "react-hot-toast";
import ClipLoader from "react-spinners/ClipLoader";
import { LoadingContext } from "../Home/ContextProvider";

const ClientDetails = ({ route }) => {
  const { increaseLoadingCounter, decreaseLoadingCounter, decreaseUploadingLoadingCounter, increaseUploadingLoadingCounter } = useContext(LoadingContext);
  const { user } = useSelector((state) => state.user);
  const urlParams = new URLSearchParams(window.location.search);
  const customerId = urlParams.get("client-details");
  const { create_Folder, Customer_Details, get_Folder, get_trash_Folder, upload_file, trashed_files, deleted_files, restored_files, important_status, approval_status, add_Comment } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("details");
  const [data, setdata] = useState(Customer_Details);
  const folderRef = React.useRef(null);
  const fileRef = React.useRef(null);
  const [switchState, setSwitchState] = useState(true);
  const [rootFolder, setrootFolder] = useState(data?.rootFolder);

  useEffect(() => {
    setdata(Customer_Details);
  }, [Customer_Details]);

  useEffect(() => {
    setSwitchState(data?.status === "active" ? true : false);
    setrootFolder(data?.rootFolder);
  }, [data]);

  useEffect(() => {
    if (activeTab === "details") {
      increaseLoadingCounter();
      dispatch(getCustumerDetails(customerId)).then(() => {
        decreaseLoadingCounter();
      });
    }
  }, [activeTab, customerId]);

  const handleGetCurrentFolder = () => {
    if ((data?._id === customerId) && rootFolder) {
      increaseLoadingCounter();
      dispatch(getFolder(data?._id, rootFolder)).then(() => {
        decreaseLoadingCounter();
      });
    }
  };
  const handleGetTrashFolder = () => {
    if (data?._id === customerId) {
      increaseLoadingCounter();
      dispatch(getFolderFromTrash(data?._id)).then(() => {
        decreaseLoadingCounter();
      });
    }
  };

  useEffect(() => {
    handleGetCurrentFolder();
  }, [data, create_Folder, upload_file, trashed_files, restored_files, important_status, approval_status, add_Comment]);

  useEffect(() => {
    handleGetTrashFolder();
  }, [data, trashed_files, deleted_files, restored_files]);

  // useEffect(() => {
  //   dispatch(
  //     UserUpdateCustomerStatus(data?._id, switchState ? "active" : "inactive")
  //   );
  // }, [switchState]);

  // const [progress, setProgress] = useState(-1);

  // const onZipUpdate = (metadata) => {
  //   setProgress(metadata?.percent);
  //   
  // };
  // const throttledZipUpdate = throttle(onZipUpdate, 50);
  const handleFolderUpload = async (e) => {
    increaseUploadingLoadingCounter()
    const files = e.target.files;
    setuploadingFail(false); setfileName(null);

    const content = await createZip(files);
    const formData = new FormData();
    formData.append("folder", content);
    await axios.post(`${backend_api}/api/user/uploadFolder/${data?._id}/${rootFolder}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${Cookies.get("access_token")}`,
        },
        onUploadProgress: (event) => {
          if (Math.round((100 * event.loaded) / event.total) === 100) {
            decreaseUploadingLoadingCounter()
          }
          setfileName('folder');
          setuploadProgress(Math.round(100 * event.loaded) / event.total);
          setUploadedMB((event.loaded / (1024 * 1024)).toFixed(2));
        },
      }
    ).then((res) => {
      toast.success(res.data?.message);
      setuploadProgress(100);
      // setUploadedMB(0);
      setfileName(null);
      handleGetCurrentFolder();
    }).catch((err) => {
      decreaseUploadingLoadingCounter()
      setuploadingFail(true)
      toast.error(err.response.data?.message || "Something went wrong");
    });
  };

  const createZip = async (files) => {
    const zip = new JSZip();
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      zip.file(file.webkitRelativePath, file);
    }
    const content = await zip.generateAsync({ type: "blob" });
    return content;
  };

  const ClientHeader = ({ goBack }) => {
    const [downloading, setDownloading] = useState(false);
    return (
      <div className="client-header">
        <div className="client-header-left">
          <img
            src="https://img.icons8.com/material-sharp/50/left.png"
            alt="go-back"
            id="client-back-btn"
            onClick={() => { navigate('/clients') }}
          />
          <h1 id="client-header-title">{data?.name}</h1>
        </div>
        <div className="client-header-right">
          <button
            id="client-download-btn"
            onClick={() => {
              dispatch(downloadDeleted(data?._id, setDownloading));
            }}
            disabled={downloading}
          >
            {
              downloading ?
                <div style={{ width: '7rem' }}>
                  <ClipLoader
                    color={'#fff'}
                    loading={true}
                    size={10}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
                :
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}><CloudDownloadOutlinedIcon /><span>Download Deleted</span></div>
            }
          </button>
        </div>
      </div>
    )
  };

  const ClientTabs = ({ activeTab, setActiveTab }) => {
    // useEffect(()=> {
    //   if(activeTab === "details") dispatch(getCustumerDetails(urlParams.get("client-details")));
    // }, [activeTab])
    return (
      <div className="client-tabs">
        <div
          className={`client-tab${activeTab === "details" ? "-active" : ""}`}
          onClick={() => {
            setActiveTab("details");
          }}
          style={{ paddingBottom: '10px' }}
        >
          <img
            width="20"
            height="20"
            src="https://img.icons8.com/plumpy/96/user--v1.png"
            alt="guest-male"
          />
          <p className="client-tab-text" style={{ marginBottom: '0px' }}>Client Details</p>
        </div>
        <div
          className={`client-tab${activeTab === "files" ? "-active" : ""}`}
          onClick={() => {
            setActiveTab("files");
          }}
          style={{ paddingBottom: '10px' }}
        >
          <img
            width="20"
            height="20"
            src="https://img.icons8.com/plumpy/96/folder-invoices--v1.png"
            alt="folder-invoices--v1"
          />
          <p className="client-tab-text" style={{ marginBottom: '0px' }}>Files & Folders</p>
        </div>
        <div
          className={`client-tab${activeTab === "trash" ? "-active" : ""}`}
          onClick={() => {
            setActiveTab("trash");
          }}
          style={{ paddingBottom: '10px' }}
        >
          <img
            width="20"
            height="20"
            src="https://img.icons8.com/plumpy/96/trash--v1.png"
            alt="folder-invoices--v1"
          />
          <p className="client-tab-text" style={{ marginBottom: '0px' }}>Trash</p>
        </div>
      </div>
    );
  };

  const ClientFilesButtons = () => (
    <div className="client-files-buttons-container">
      <div className="client-files-buttons-container-left">
        <button onClick={() => setfolderModal(true)} id="client-files-tab-btn">
          Create Folder
        </button>
      </div>
      <div className="client-files-buttons-container-right">
        <button id="client-files-tab-btn" onClick={() => setfileModal(true)}>
          Upload Files
        </button>

        <button
          id="client-files-tab-btn"
          onClick={() => setUploadFolderModel(true)}
        >
          Upload Folder
        </button>
      </div>
    </div>
  );
  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  const DeleteClient = () => (
    <button id="client-delete-btn" onClick={() => setDeleteClientModal(true)}>
      Delete Client
    </button>
  );

  const ClientPersonalDetails = () => (
    <div className="client-details-container">
      <div className="client-details-upper-container">
        <div className="client-details-status-container">
          <div className="client-details-status-container-left">
            <img
              width="20"
              height="20"
              src="https://img.icons8.com/fluency-systems-regular/48/guest-male.png"
              alt="guest-male"
            />
            <div className="client-details-client-name-container">
              <p className="client-details-client-name">{data?.name}</p>
              <p className="client-details-client-email">{data?.email}</p>
            </div>
          </div>
          <div className="client-details-status-container-right">
            <Switch
              onChange={(e) => {
                setSwitchState(e);
                increaseLoadingCounter();
                dispatch(UserUpdateCustomerStatus(data?._id, e)).then(() => {
                  decreaseLoadingCounter();
                });
              }}
              checked={switchState}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor="#5DCE7B"
            />
            <p id="client-details-status">{data?.status}</p>
          </div>
        </div>
      </div>
      <div className="client-details-lower-container">
        <div className="client-details-lower-container-left">
          <p className="client-details-client-subtitle">
            Created Date : {moment(data?.createdAt).format("DD/MM/yyyy")}
          </p>
        </div>
        <div className="client-details-lower-container-left">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <img
              id="folder-card-icon"
              src="https://img.icons8.com/small/50/folder-invoices--v1.png"
              alt="folder-invoices--v1"
            />
            <p className="client-details-client-subtitle">
              {folders?.length}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <img
              id="folder-card-icon"
              src="https://img.icons8.com/ios/50/copy--v1.png"
              alt="copy--v1"
            />
            <p className="client-details-client-subtitle">{data?.totalFiles}</p>
          </div>
        </div>
      </div>
    </div>
  );

  const DeleteClientModal = () => {
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      border: "none",
      boxShadow: 24,
      p: 4,
    };
    return (
      <div>
        <Modal
          open={deleteClientModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div style={{ display: "flex", marginBottom: "20px" }}>
              <p
                style={{
                  fontFamily: "Nunito",
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#3F414E",
                }}
              >
                Are you sure you want to delete {data?.username} ?
              </p>
              <Button
                onClick={() => setDeleteClientModal(false)}
                style={{ marginLeft: "auto", height: "30px" }}
              >
                <span>X</span>
              </Button>
            </div>
            <Button onClick={() => setDeleteClientModal(false)}>Cancel</Button>
            <Button
              onClick={() => {
                increaseLoadingCounter();
                dispatch(UserDeleteCustomer(data?._id, navigate)).then(() => {
                  decreaseLoadingCounter();
                })
              }}
              variant="contained"
            >
              Delete Client
            </Button>
          </Box>
        </Modal>
      </div>
    );
  };

  const CreateFolderModal = () => {
    const [folderName, setFolderName] = useState(""); // Initialize folderName state

    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
    };

    const handleFolderNameChange = (e) => {
      // Update folderName state when the input changes
      setFolderName(e.target.value);
    };

    return (
      <div>
        <Modal
          open={folderModal}
        >
          <div style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 550,
            backgroundColor: '#fff',
            border: "1px solid gray",
            boxShadow: 24,
            p: 4,
            borderRadius: '20px',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: '20px',
            alignItems: 'center',
            height: '200px'
          }} >
            <div style={{ display: "flex", width: '100%', alignItems: 'center', }}>
              <span style={{ color: '#000', fontSize: '18px', fontFamily: 'Nunito', fontWeight: 'bold', marginLeft: '34%' }}>Create New Folder</span>
              <Button
                onClick={() => {
                  setfolderModal(false);
                }}
                style={{ marginLeft: 'auto' }}
              >
                <span style={{ fontFamily: 'Nunito', fontWeight: 'bold', fontSize: '18px', color: '#000' }}>x</span>
              </Button>
            </div>
            <div style={{ display: 'flex', backgroundColor: '#f2f3f7', alignItems: 'center', padding: '10px', marginLeft: '20px', marginRight: '20px', width: '300px' }}>
              <input
                type="text"
                placeholder="Folder Name"
                name="FolderName"
                value={folderName} // Bind input value to folderName state
                onChange={handleFolderNameChange} // Handle input change
                style={{ padding: "10px", borderStyle: 'none', width: '75%', backgroundColor: '#f2f3f7', outline: 'none' }}
              />
              <Button
                onClick={() => {
                  setfolderModal(false);
                  increaseLoadingCounter();
                  dispatch(createFolder(data?._id, rootFolder, folderName)).then(() => {
                    decreaseLoadingCounter();
                  })
                }}
                style={{ backgroundColor: '#5DCE7B', color: '#000', width: '70px', textTransform: 'capitalize', borderRadius: '20px' }}
              >
                <span style={{ color: '#000', fontFamily: 'Nunito', }}>Create</span>
              </Button>

            </div>
          </div>
        </Modal>
      </div>
    );
  };



  const UploadFileModal = () => {
    const [FileName, setFileName] = useState([])
    const handleFile = (e) => {
      const filesinput = e.target.files;
      setFiles(filesinput);
      const fileNames = Array.from(filesinput).map((file) => file.name);
      setFileName(fileNames)
    };
    const handleUploadFile = async () => {
      if (!files) return toast.error('Please choose a file')
      setfileModal(false)
      dispatch(uploadFile(data?._id, files, rootFolder, increaseUploadingLoadingCounter, decreaseUploadingLoadingCounter))
    };

    const [files, setFiles] = useState();

    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      boxShadow: 24,
      p: 4,
    };



    return (
      <div>
        <Modal
          open={fileModal}
        >
          <div style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            backgroundColor: '#fff',
            border: "1px solid gray",
            boxShadow: 24,
            p: 4,
            borderRadius: '20px',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: '20px',
            alignItems: 'center',
            height: '200px'
          }}>
            <div style={{ display: "flex", width: '100%', alignItems: 'center', }}>
              <span style={{ color: '#000', fontSize: '18px', fontFamily: 'Nunito', fontWeight: 'bold', marginLeft: '40%' }}>Upload Files</span>
            </div>
              <Button
                onClick={() => {
                  setfileModal(false);
                }}
                style={{ position:'absolute', right: 0, top: 0}}
              >
                <span style={{ fontFamily: 'Nunito', fontWeight: 'bold', fontSize: '18px', color: '#000' }}>x</span>
              </Button>
            <input
              type="file"
              placeholder="Upload File"
              name="files"
              multiple
              style={{ display: "none" }}
              ref={fileRef}
              onChange={handleFile} // Handle input change
            />
            {FileName?.length !== 0 && <div style={{ alignSelf: 'center' }}>
              {FileName?.length == 1 ? <span>{FileName[0]}</span> :
                <span>{FileName?.length} Files Selected</span>}
            </div>}
            <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '20px', width: '300px' }}>
              <Button style={{ backgroundColor: '#3F414E', color: '#000', width: '100px', textTransform: 'capitalize', borderRadius: '20px' }} onClick={() => fileRef.current.click()}>
                <span style={{ color: '#fff', fontFamily: 'Nunito', }}>Choose file</span>
              </Button>
              <Button style={{ backgroundColor: '#5DCE7B', color: '#000', width: '100px', textTransform: 'capitalize', borderRadius: '20px' }} onClick={() => handleUploadFile()}>
                <span style={{ color: '#000', fontFamily: 'Nunito', }}>Upload</span>
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  };

  const UploadFolderModal = () => {
    const handleFolderChange = async (event) => {
      const folderFiles = {};
      const files = event.target.files;

      const addFolder = (folderPath) => {
        const folders = folderPath.split('/');
        let currentPath = '';
        for (const folder of folders) {
          currentPath = currentPath ? `${currentPath}/${folder}` : folder;
          if (!folderFiles[currentPath]) {
            folderFiles[currentPath] = [];
          }
        }
      };

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const folder = file.webkitRelativePath.split('/').slice(0, -1).join('/');
        addFolder(folder);
        folderFiles[folder].push(file);
      }

      setFolder(folderFiles);
    };
    const [Folder, setFolder] = useState()

    const handleFolderUpload = () => {
      if (!Folder) return toast.error('Please Choose Folder')
      setUploadFolderModel(false)
      dispatch(uploadFolder(data?._id, Folder, rootFolder, increaseUploadingLoadingCounter, decreaseUploadingLoadingCounter));
    }

    return (
      <div>
        <Modal
          open={UploadFolderModel}
        >
          <div style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            backgroundColor: '#fff',
            border: "1px solid gray",
            boxShadow: 24,
            p: 4,
            borderRadius: '20px',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: '20px',
            alignItems: 'center',
            height: '200px'
          }}>
            <div style={{ display: "flex", width: '100%', alignItems: 'center', }}>
              <span style={{ color: '#000', fontSize: '18px', fontFamily: 'Nunito', fontWeight: 'bold', marginLeft: '40%' }}>Upload Folder</span>
            </div>
            <Button
                onClick={() => {
                  setUploadFolderModel(false);
                }}
                style={{ position:'absolute', right: 0, top: 0}}
              >
                <span style={{ fontFamily: 'Nunito', fontWeight: 'bold', fontSize: '18px', color: '#000' }}>x</span>
              </Button>
            {Folder && (
              <div style={{ marginTop: '20px', textAlign: 'left' }}>
                <span style={{ color: '#000', fontFamily: 'Nunito', fontWeight: 'bold',display:'flex' }}>Selected Folder: <span>{Object.keys(Folder)[0]}</span>
                </span>
              </div>
            )}
            <input
              type="file"
              webkitdirectory=""
              directory=""
              style={{ display: "none" }}
              ref={folderRef}
              onChange={handleFolderChange}
            />
            <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '20px', width: '350px' }}>
              <Button style={{ backgroundColor: '#3F414E', color: '#000', width: '140px', textTransform: 'capitalize', borderRadius: '20px' }} onClick={() => folderRef.current.click()}>
                <span style={{ color: '#fff', fontFamily: 'Nunito', }}>Choose folder</span>
              </Button>
              <Button style={{ backgroundColor: '#5DCE7B', color: '#000', width: '140px', textTransform: 'capitalize', borderRadius: '20px' }} onClick={() => { handleFolderUpload() }} >
                <span style={{ color: '#000', fontFamily: 'Nunito', }}>Upload</span>
              </Button>

            </div>
          </div>
        </Modal>
      </div>
    );
  };
  const FileUploading = () => {
    const [progress, setProgress] = React.useState(10);

    React.useEffect(() => {
      const timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
      }, 800);
      return () => {
        clearInterval(timer);
      };
    }, []);


    return (
      <div id="file-uploading-modal">
        <div
          style={{
            display: "flex",
            borderBottomColor: "#000",
            borderBottom: "1px solid rgb(212, 212, 212)",
            alignItems: 'center'
          }}
        >
          {uploadingFail ? <ErrorOutlineIcon style={{ color: 'red', width: '35px', height: '35px', marginTop: '-5' }} /> :
            <div style={{ marginRight: '10px' }}>
              <CircularProgressWithLabel
                value={uploadProgress}
              />

            </div>
          }
          <div>
            {uploadProgress < 100 && uploadingFail == false ? <span
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                fontFamily: "Nunito",
                marginLeft: '10px'
              }}
            >
              Uploading 1 of 1 Files
            </span> :
              uploadingFail ? <span
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  fontFamily: "Nunito",
                }}
              >
                Upload failed
              </span> : <span
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  fontFamily: "Nunito",
                }}
              >
                Uploaded Succesfully
              </span>

            }
            <br />
          </div>
          <Button
            onClick={() => {
              setfileName(null);
              setuploadingFail(false)
            }}
            style={{ margin: 0, padding: "0px", marginLeft: "auto" }}
          >
            <span style={{ margin: 0, padding: 0 }}>X</span>
          </Button>
        </div>

        <div
          style={{ marginTop: "20px", overflow: "scroll", overflowX: "hidden" }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={Folderpng}
              style={{ height: "30px", marginRight: "10px" }}
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  fontFamily: "Nunito",
                }}
              >
                {fileName}
              </span>
            </div>
            {uploadProgress < 100 ? (
              <CircularProgress
                variant="determinate"
                size={30}
                value={uploadProgress}
                style={{ marginRight: "15px", marginLeft: "auto" }}
              />
            ) : uploadingFail ? (
              <ErrorOutlineIcon style={{ color: "red", marginLeft: "auto" }} />
            ) : (
              <CheckCircleOutlineIcon
                color="#5DCE7B"
                style={{ color: "#5DCE7B", marginLeft: "auto" }}
              />
            )}
          </div>
        </div>
      </div >
    );
  };

  const ClientLoginDetails = () => (
    <div className="client-details-container">
      <div className="client-details-upper-container">
        <div className="client-details-status-container">
          <div className="client-details-status-container-left">
            <div className="client-details-client-name-container">
              <p className="client-details-client-login">
                Client Login History
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="client-details-lower-container">
        <div className="client-details-lower-container-left">
          <p className="client-details-client-subtitle">Last Login:</p>
          <span className="client-details-client-subtitle-2">
            {moment(data?.lastLogin).format("DD/MM/yyyy", "LT")}{" "}
            {moment(data?.lastLogin).format("LT")}
          </span>
        </div>
      </div>
    </div>
  );

  const ClientFilesHistory = () => (
    <div className="client-details-container">
      <div className="client-details-upper-container">
        <div className="client-details-status-container">
          <div className="client-details-status-container-left">
            <div className="client-details-client-name-container">
              <p className="client-details-client-login">Files History</p>
            </div>
          </div>
        </div>
      </div>
      <div className="client-details-lower-container">
        <div className="client-details-lower-container-left">
          <p className="client-details-client-subtitle">Deleted Files:</p>
          <span className="client-details-client-subtitle-2">
            {data?.deletedFiles} Files
          </span>
        </div>
      </div>
    </div>
  );

  const ClientLoginURL = () => (
    <div className="client-details-container">
      <div className="client-details-upper-container">
        <div className="client-details-status-container">
          <div className="client-details-status-container-left">
            <div className="client-details-client-name-container">
              <p className="client-details-client-login">Client Portal -  Login URL</p>
            </div>
          </div>
        </div>
      </div>
      <div className="client-details-lower-container">
        <div className="client-details-lower-container-left">
          <img
            width="30"
            height="30"
            src="https://img.icons8.com/glyph-neue/64/888888/link.png"
            alt="link"
          />
          <a
            href={`/ClientLogin?userId=${data?.userId}`}
            target="_blank"
            className="client-details-client-link"
            rel="noreferrer"
            style={{fontSize: '0.7rem'}}
          >
            {backend_api}/ClientLogin?userId={data?.userId}
          </a>
        </div>
        <div className="client-details-lower-container-right">
          <p className="client-details-client-link-btn" onClick={() => {
            navigator.clipboard.writeText(`${backend_api}/ClientLogin?userId=${data?.userId}`);
            toast.success("Link copied to clipboard!");
          }}>
            Copy Link
          </p>
        </div>
      </div>
    </div>
  );

  const ClientCredentials = () => {
    const [showPassword, setShowPassword] = useState(false);
    return (
      <div className="client-details-container">
        {/* USERNAME */}
        <div className="client-details-upper-container">
          <div className="client-details-status-container">
            <div className="client-details-status-container-left">
              <div className="client-details-client-name-container">
                <p className="client-details-client-login">Client Portal - Username</p>
              </div>
            </div>
          </div>
        </div>
        <div className="client-details-lower-container">
          <div className="client-details-lower-container-left">
            <p className="client-details-client-username">{data?.username}</p>
          </div>
        </div>

        {/* PASSWORD */}
        <div className="client-details-upper-container">
          <div className="client-details-status-container">
            <div className="client-details-status-container-left">
              <div className="client-details-client-name-container">
                <p className="client-details-client-login">Client Portal - Password</p>
              </div>
            </div>
          </div>
        </div>
        <div className="client-details-lower-container">
          <div className="client-details-lower-container-left">
            <p className="client-details-client-username">{showPassword ? data?.password : "* * * * * * * *"}</p>
          </div>
          <div className="client-details-lower-container-right">
            <p className="client-details-client-link-btn" onClick={() => setShowPassword(!showPassword)}>{showPassword ? "Hide" : "Show"} Password</p>
          </div>
        </div>
      </div>
    )
  };

  const Folder = ({ item, deleted }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div id="client-files-folder-item">
        <IconButton
          aria-label="more"
          id="long-button"
          // aria-controls={open ? "long-menu" : undefined}
          // aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
        >
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {item?.isTrashed ? (
              <MenuItem
                onClick={() => {
                  increaseLoadingCounter();
                  dispatch(restoreFolder(data?._id, item._id, rootFolder)).then(() => {
                    decreaseLoadingCounter();
                  })
                  handleClose();
                }}
              >
                <RestoreFromTrash
                  style={{
                    marginRight: "10px",
                  }}
                />{" "}
                Restore
              </MenuItem>
            ) : (
              <MenuItem
                onClick={() => {
                  setrootFolder(item._id);
                  const newData = {
                    name: item.name,
                    id: item._id,
                  };
                  // Check if item._id already exists in the Path array
                  const isIdPresent = Path.some(pathData => pathData.id === item._id);
                  if (!isIdPresent) {
                    // If item._id is not present, add newData to the Path array
                    setPath([...Path, newData]);
                  }
                  handleClose();
                }}
              >
                <RemoveRedEyeOutlined
                  style={{
                    marginRight: "10px",
                  }}
                />{" "}
                View
              </MenuItem>
            )}
            {item?.isTrashed ? (
              <MenuItem
                onClick={() => {
                  increaseLoadingCounter();
                  dispatch(deleteFolder(data?._id, item._id)).then(() => {
                    decreaseLoadingCounter();
                  })
                  handleClose();
                }}
              >
                <DeleteForever
                  style={{
                    marginRight: "10px",
                  }}
                />{" "}
                Delete
              </MenuItem>
            ) : (
              <MenuItem
                onClick={() => {
                  increaseLoadingCounter();
                  dispatch(moveFolderToTrash(data?._id, item._id)).then(() => {
                    decreaseLoadingCounter();
                  })
                }}
              >
                <DeleteOutlineIcon
                  style={{
                    marginRight: "10px",
                  }}
                />{" "}
                Move to trash
              </MenuItem>
            )}
          </Menu>
          <MoreVertIcon onClick={handleClick} />
        </IconButton>
        <img
          src="https://img.icons8.com/small/200/7b7b7b/folder-invoices--v1.png"
          alt="folder-invoices--v1"
          id="client-files-folder-item-img"
          onClick={() => {
            if (deleted == false) {
              setrootFolder(item._id);
              const newData = {
                name: item.name,
                id: item._id,
              };
              // Check if item._id already exists in the Path array
              const isIdPresent = Path.some(pathData => pathData.id === item._id);
              if (!isIdPresent) {
                // If item._id is not present, add newData to the Path array
                setPath([...Path, newData]);
              }
            }
          }}
        />
        <p id="client-files-folder-item-title">{item.name}</p>
      </div>
    );
  };

  const ClientFolders = ({ folders }) => {
    return (
      <div className="client-files-items-container">
        {/* Folders */}
        <div className="client-files-folders-container">
          <h1 id="client-files-folders-container-title">
            Folder(s) - {folders?.length}{" "}
          </h1>
          <div className="client-files-folders">
            {folders?.map((item, index) => (
              <Folder deleted={false} key={index} item={item} />
            ))}
          </div>
        </div>
      </div>
    );
  };

  const ImageGalleryHeader = ({
    FileModal,
    originalFilesArray,
    FilesArray,
    FolderPath,
    setImagesArray,
  }) => {
    return (
      <div id="image-gallery-header" >
        <div className="image-gallery-header-breadcrumbs">
          {FolderPath.length > 1 && <img
            src="https://img.icons8.com/material-sharp/50/left.png"
            alt="go-back"
            id="client-back-btn"
            onClick={() => {
              if (Path.length === 2) {
                setrootFolder(data?.rootFolder)
              } else {
                setrootFolder(FolderPath[FolderPath.length - 2].id)
              }
              Path.pop(); // Remove the last element
              setPath([...Path]);
            }}
          />}
          <img
            src="https://img.icons8.com/small/200/3F414E/folder-invoices--v1.png"
            alt="folder-invoices--v1"
            id="image-gallery-header-breadcrumbs-img"
          />
          {FolderPath?.map((item, index) => (

            index > 0 && index > FolderPath.length - 4 ? (
              <button  onClick={() => {
                setrootFolder(item.id)
                const arrayOfObjects = Path
                if (index !== -1) {
                  arrayOfObjects.splice(index + 1);
                }
                setPath(arrayOfObjects);
              }} key={index} style={{ display: "flex" }}>
                <img
                  src="https://img.icons8.com/fluency-systems-filled/48/3F414E/right.png"
                  alt="right"
                  id="image-gallery-header-breadcrumbs-img"
                />
                <p id="image-gallery-header-breadcrumbs-text">{item.name}</p>
              </button>
            ) : null
          ))}
        </div>
        <div className="image-gallery-header-options mainPortal">
          {/* <button id="client-files-tab-btn" 
            onClick={() => FileModal(true)}>
              Upload Files
            </button> */}
          {/* <div className="image-gallery-header-options-item">
            <img
              width="30"
              height="30"
              src="https://img.icons8.com/ios-filled/50/5dce7b/toggle-on.png"
              alt="toggle-on"
            />
            <p id="image-gallery-header-options-item-text">Reviews</p>
          </div> */}
          <div
            onClick={() => {
              setImagesArray(
                originalFilesArray.filter((file) => file.comments.length > 0)
              );
            }}
            className="image-gallery-header-options-item"
          >
            <img width="30" height="30" src={commments} alt="comments" />
            <p id="image-gallery-header-options-item-text">Comments</p>
          </div>
          <div
            onClick={() => {
              setImagesArray(
                originalFilesArray.filter((file) => file.isApproved === true)
              );
            }}
            className="image-gallery-header-options-item"
          >
            <img width="30" height="30" src={approved} alt="approved" />
            <p id="image-gallery-header-options-item-text">Approved</p>
          </div>
          <div
            onClick={() => {
              setImagesArray(
                originalFilesArray.filter((file) => file.isImportant === true)
              );
            }}
            className="image-gallery-header-options-item"
          >
            <img width="30" height="30" src={important} alt="important" />
            <p id="image-gallery-header-options-item-text">Important</p>
          </div>
          <div
            onClick={() => {
              setImagesArray(originalFilesArray);
            }}
            className="image-gallery-header-options-item"
          >
            <img width="30" height="30" src={viewall} alt="view all" />
            <p id="image-gallery-header-options-item-text">View All</p>
          </div>
        </div>
      </div>
    );
  };

  const navigate = useNavigate();
  const [folderModal, setfolderModal] = useState(false);
  const [fileModal, setfileModal] = useState(false);
  const [UploadFolderModel, setUploadFolderModel] = useState(false)

  const { clientURL } = useSelector((state) => state.client);
  const [uploadProgress, setuploadProgress] = useState();
  const [fileName, setfileName] = useState();
  const [uploadingFail, setuploadingFail] = useState(false);
  const [Path, setPath] = useState([{
    name: 'rootFolder',
    id: data?.rootFolder,
  }]);
  const [UploadedMB, setUploadedMB] = useState();
  const [filesArray, setFilesArray] = useState([]);
  const [deleteClientModal, setDeleteClientModal] = useState(false);
  const [folders, setFolders] = useState([]);
  const [deletedFolders, setDeletedFolders] = useState([]);

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    setFilesArray(get_Folder?.files);
    setFolders(get_Folder?.folders?.filter((item) => !item.isTrashed));
    setDeletedFolders(get_Folder?.folders?.filter((item) => item.isTrashed));
  }, [get_Folder]);

  useEffect(() => {
    setfileModal(false);
  }, [upload_file]);

  useEffect(() => {
    handleGetCurrentFolder();
  }, [rootFolder]);

  const handleDrop = async (event) => {
    event.preventDefault();
    setIsDraggingOver(false);
    const files = event.dataTransfer.files
    dispatch(uploadFile(data?._id, files, rootFolder, increaseUploadingLoadingCounter, decreaseUploadingLoadingCounter))
  }

  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = () => {
    setIsDraggingOver(false);
  };




  return (
    <div id="client-page">
      <ClientHeader goBack={goBack} />
      {/* {fileName && <FileUploading />} */}
      <hr
        style={{
          width: "100%",
          height: "1px",
          backgroundColor: "#E5E5E5",
          border: "none",
          margin: "0",
          padding: "0",
        }}
      />
      <ClientTabs activeTab={activeTab} setActiveTab={setActiveTab} />
      <hr
        style={{
          width: "100%",
          height: "1px",
          backgroundColor: "#E5E5E5",
          border: "none",
          margin: "0",
          marginTop: "-20px",
          padding: "0",
        }}
      />
      {activeTab === "details" && (
        <>
          <DeleteClientModal />
          <h1 id="client-details-page-title">Client Details</h1>
          <div className="client-details-items-container">
            <ClientPersonalDetails />
            <ClientLoginDetails />
            <ClientLoginURL />
            <ClientFilesHistory />
            <ClientCredentials />
          </div>

          <DeleteClient />
        </>
      )}

      {activeTab === "files" && (
        <>
          {get_Folder?.folders.length === 0 && get_Folder?.files.length === 0 ? (
            <div className="client-files-items-container">
              <ImageGalleryHeader
                FilesArray={filesArray}
                setImagesArray={setFilesArray}
                FolderPath={Path}
              />
              <ClientFilesButtons />
              <CreateFolderModal />
              <UploadFileModal />
              <UploadFolderModal />
              <div style={{ backgroundColor: isDraggingOver ? '#EEF5FF' : 'white', padding: '5px 1rem', marginTop: '5px', border: isDraggingOver ? '2px solid #7071E8' : null, borderRadius: '20px' }}
                onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop} className="client-files-items-container-center">
                <img draggable={false} src={noFiles} alt="no files found" id="no-files-img" />
                <ClientFilesButtons />
                {isDraggingOver && <div style={{ position: 'absolute', bottom: '20px', display: 'flex', flexDirection: 'column' }}>
                  <img src={UpArrow} style={{ height: "25px", marginRight: "15px", width: '20px', alignSelf: 'center', marginBottom: '5px' }} />
                  <span style={{ color: '#fff', backgroundColor: '#5DCE7B', padding: '30px', paddingTop: '20px', paddingBottom: '20px', borderRadius: '30px' }}>Drop Your Files to Upload</span>
                </div>}
              </div>
            </div>
          ) : (
            <div style={{ width:'100%' }}>
              <ImageGalleryHeader
                FilesArray={filesArray}
                setImagesArray={setFilesArray}
                FolderPath={Path}
                originalFilesArray={get_Folder?.files}
              />
              <ClientFilesButtons />
              <CreateFolderModal />
              <UploadFileModal />
              <UploadFolderModal />
              {
                get_Folder?.folders.length > 0 &&
                <ClientFolders
                  folders={get_Folder?.folders}
                />
              }
                <div className="imageGalleryMain"
                  style={{ backgroundColor: isDraggingOver ? '#EEF5FF' : '#fff', padding: '20px', border: isDraggingOver ? '2px solid #7071E8' : null }}
                  onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
                  <ImageGallery
                    FilesArray={filesArray}
                    deletedFile={true}
                    Custumer={false}
                    rootFolder={rootFolder}
                  />

                </div>
              {/* {
                (get_trash_Folder?.folders.length > 0 || get_trash_Folder?.files.length > 0) &&
                <>
                  <h1 style={{ fontSize: '20px' }} id="client-files-folders-container-title">
                    Deleted Files / Folders
                  </h1>
                  {
                    get_trash_Folder?.folders.length > 0 &&
                    <div className="client-files-folders-container">
                      <h1 id="client-files-folders-container-title">
                        Deleted Folder(s) - {deletedFolders?.length}{" "}
                      </h1>
                      <div className="client-files-folders">
                        {get_trash_Folder?.folders?.map((item, index) => (
                          <Folder deleted={true} key={index} item={item} />
                        ))}
                      </div>
                    </div>
                  }
                  {
                    get_trash_Folder?.files.length > 0 &&
                    <ImageGallery
                      FilesArray={filesArray}
                      deletedImagesArray={get_trash_Folder?.files}
                      deletedFile={false}
                      Custumer={false}
                      rootFolder={rootFolder}
                    />
                  }
                </>
              } */}

            </div>
          )}
        </>
      )}

      {activeTab === "trash" && (
        <>
          {
            (get_trash_Folder?.folders.length > 0 || get_trash_Folder?.files.length > 0) ?
            <>

              {
                get_trash_Folder?.folders.length > 0 &&
                <div className="client-files-folders-container">
                  <h1 id="client-files-folders-container-title">
                    Deleted Folder(s) - {get_trash_Folder?.folders?.length}{" "}
                  </h1>
                  <div className="client-files-folders">
                    {get_trash_Folder?.folders?.map((item, index) => (
                      <Folder deleted={true} key={index} item={item} />
                    ))}
                  </div>
                </div>
              }
              {
                get_trash_Folder?.files.length > 0 &&
                <ImageGallery
                  FilesArray={filesArray}
                  deletedImagesArray={get_trash_Folder?.files}
                  deletedFile={false}
                  Custumer={false}
                  rootFolder={rootFolder}
                />
              }
            </>
            :
            <div style={{ display:'flex', alignItems:'center', justifyContent:'center'}}>
             <img draggable={false} src={emptyFiles} alt="no files found" id="no-files-img" />
            </div>
          }
        </>

      )}

    </div >
  );
};

export default ClientDetails;