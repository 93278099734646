import { configureStore } from "@reduxjs/toolkit";
import { sidebarReducer } from "./reducers/sidebarReducer";
import { userReducer } from "./reducers/userReducer";
import { adminReducer } from "./reducers/adminReducer";
import { clientReducer } from "./reducers/clientReducer";

export const store = configureStore({
  reducer: {
    sidebar: sidebarReducer,
    user: userReducer,
    admin: adminReducer,
    client: clientReducer,
  },
});


export const backend_api = "https://gallerystudio.in";