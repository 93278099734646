import React, { useEffect, useState } from "react";
import "./clientsStyles.css";
import FolderCards from "./FolderCards";
import AddClientForm from "../../components/AddClientForm/AddClientForm";
import { useDispatch, useSelector } from "react-redux";
import { getCostumers } from "../../redux/actions/userAction";
import Cookies from "js-cookie";
import { setAddClientOption } from "../../redux/actions/sidebarAction";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Clients = ({ navigation }) => {
  const dispatch = useDispatch();
  const { Add_ClientOption } = useSelector((state) => state.sidebar);

  const { user, get_Costumers, planSettings, user_storage_usage } = useSelector((state) => state.user);
  const [showAddClient, setShowAddClient] = useState(true);
  const [showStorageLimitExceeded, setShowStorageLimitExceeded] = useState(false);

  useEffect(() => {
    dispatch(setAddClientOption(false));
  }, []);

  useEffect(() => {
    if(user && get_Costumers && planSettings){
      setShowAddClient(get_Costumers?.customers?.length < planSettings[user.subscriptionType].customersLimit);
    }
  }, [user, get_Costumers, planSettings]);

  useEffect(() => {
    if(user_storage_usage){
      //if 15 percent of storage is remaining set showStorageLimitExceeded to true
      setShowStorageLimitExceeded(user_storage_usage.storageUsed > (user_storage_usage.totalStorage * 0.85));
    }
  }, [user_storage_usage]);

  return (
    <div id="clients-page">
      {Add_ClientOption ? (
        <AddClientForm />
      ) : (
        <div style={{width:'100%'}}>
          {
            !showAddClient &&
              <div style={{ display: 'flex', alignItems: 'center', gap: 5, backgroundColor: '#FFE5E1', borderRadius: '10px', padding: '10px', marginBottom: '10px'}}>
                <InfoOutlinedIcon style={{ color: '#FA6E5A', marginLeft: '10px', marginTop: '5px' }} />
                <span style={{color: '#000'}}>You've reached the limit for <b>client creations</b>. Please consider upgrading to continue.</span>
                <button type="button" onClick={()=> navigation('/pricing')} style={{backgroundColor: '#fff', border: '3px solid #FA6E5A', borderRadius: '1.2rem', color: '#FA6E5A', padding: '5px 2rem'}}>UPGRADE</button>
              </div>
          }
          {
            showStorageLimitExceeded &&
              <div style={{ display: 'flex', alignItems: 'center', gap: 5, backgroundColor: '#FFE5E1', borderRadius: '10px', padding: '10px', marginBottom: '10px'}}>
                <InfoOutlinedIcon style={{ color: '#FA6E5A', marginLeft: '10px', marginTop: '5px' }} />
                <span style={{color: '#000'}}>Your <b>storage</b> capacity is at maximum. To continue, we recommend upgrading.</span>
                <button type="button" onClick={()=> navigation('/pricing')} style={{backgroundColor: '#fff', border: '3px solid #FA6E5A', borderRadius: '1.2rem', color: '#FA6E5A', padding: '5px 2rem'}}>UPGRADE</button>
              </div>
          }
          {
            showAddClient &&
              <button
                id="clients-action-btn"
                onClick={() => {
                  dispatch(setAddClientOption(true));
                }}
              >
                <img
                  width="15"
                  height="15"
                  src="https://img.icons8.com/ios-glyphs/30/FFFFFF/plus-math.png"
                  alt="plus-math"
                />
                <p style={{ margin: 0 }}>Add New Client</p>
              </button>
          }
          <div id="client-list">
            <FolderCards customers={get_Costumers} navigation={navigation} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Clients;
