import React, { useContext } from "react";
import "./Dashboard.css";
import { useState, useEffect } from "react";
import { Button, Modal } from "@mui/material";
import { Star, StarBorder, ChatBubbleOutline, CircleOutlined, DeleteOutline, Close, CheckCircle, ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { ReactDialogBox } from "react-js-dialog-box";
import "react-js-dialog-box/dist/index.css";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { moveMultipleFilesToTrash } from "../../redux/actions/userAction";
import { addComment } from "../../redux/actions/userAction";
import { updateApprovalStatus } from "../../redux/actions/userAction";
import { updateImportantStatus } from "../../redux/actions/userAction";
import { getFolder } from "../../redux/actions/userAction";
import moment from "moment/moment";
import ReactPlayer from 'react-player';
import HeadPhoneImage from '../../assets/images/headphone.png';
import { LoadingContext } from "../Home/ContextProvider";
import ImageIcon from '../../assets/images/imageicon.png'

function Dashboard({ data, index, handleClose }) {
  const { increaseLoadingCounter, decreaseLoadingCounter } = useContext(LoadingContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [text, setText] = useState("");
  const [dialogn, setDialog] = useState(false);
  const location = useLocation();
  const { add_Comment, approval_status, important_status } = useSelector((state) => state.user);
  const [CommentClose, setCommentClose] = useState(false);
  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  // const data = location.state?.ImageArray || [];
  // const index = location.state?.index || 0;

  const [CurrentIndex, setCurrentIndex] = useState(index);
  const [allComments, setAllComments] = useState([]);
  const [fileType, setFileType] = useState(data[CurrentIndex]?.mimeType?.split("/")[0]); // ["image", "video", "audio"
  const [isImportant, setIsImportant] = useState(data[CurrentIndex]?.isImportant);
  const [isApproved, setIsApproved] = useState(data[CurrentIndex]?.isApproved);

  useEffect(() => {
    setAllComments(add_Comment?.file.comments);
  }, [add_Comment]);
  useEffect(() => {
    setAllComments(data[CurrentIndex]?.comments);
    setIsImportant(data[CurrentIndex]?.isImportant);
    setIsApproved(data[CurrentIndex]?.isApproved);
    setFileType(data[CurrentIndex]?.mimeType?.split("/")[0]);
  }, [CurrentIndex, data]);

  const handleUpdateImportantStatus = () => {
    dispatch(updateImportantStatus(data[CurrentIndex].customerId, data[CurrentIndex]._id, !isImportant));
    setIsImportant(!isImportant);
  };

  const handleUpdateApprovalStatus = () => {
    dispatch(updateApprovalStatus(data[CurrentIndex].customerId, data[CurrentIndex]._id, !isApproved));
    setIsApproved(!isApproved);
  };

  const styles = {
    icon: {
      color: "white",
      marginLeft: "10px",
      cursor: "pointer",
    },
  };

  const DeleteWarningModal = () => (
    <div className="image-gallery-modal">
      <Modal
        open={dialogn}
        onClose={() => setDialog(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          backgroundColor: '#fff',
          border: "1px solid gray",
          boxShadow: 24,
          p: 4,
          borderRadius: '20px',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: '20px',
          alignItems: 'center',
          height: '200px'
        }}>
          <p style={{ color: '#000' }}>
            Are you sure you want to delete !
          </p>
          <Button
            onClick={() => setDialog(false)}
            style={{ position: 'absolute', right: 0, top: 0 }}
          >
            <span style={{ fontFamily: 'Nunito', fontWeight: 'bold', fontSize: '18px', color: '#000' }}>x</span>
          </Button>
          <div >
            <button
              style={{ color: '#000', padding: '5px', fontFamily: 'Nunito', backgroundColor: '#5DCE7B', color: '#000', width: '80px', textTransform: 'capitalize', borderRadius: '20px', marginTop: '20px' }}
              onClick={() => {
                increaseLoadingCounter()
                dispatch(moveMultipleFilesToTrash(data[CurrentIndex].customerId, [data[CurrentIndex]._id], data[CurrentIndex]?.parent)).then(() => {
                  decreaseLoadingCounter()
                  setDialog(false);
                });
              }}
            >
              yes
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
  return (
    <div className='openimagecontainer'>
      {/* {dialogn && <ReactDialogBox
        style={{ borderRadius: "20px" }}
        closeBox={() => setDialog(false)}
        modalWidth='550px'
        headerBackgroundColor='transparent'
        headerTextColor='white'
        headerHeight='65'
        borderRadius="20px"
        closeButtonColor='white'
        bodyBackgroundColor='white'
        bodyTextColor='black'
        bodyHeight='200px'

      >
        <div>
          <p style={{ color: "black", fontSize: "21px", marginLeft: "100px" }}>Are you sure you want to delete !</p>
          <Button onClick={() => {
              increaseLoadingCounter()
              dispatch(moveMultipleFilesToTrash(data[CurrentIndex].customerId, [data[CurrentIndex]._id], data[CurrentIndex]?.parent)).then(() => {
                decreaseLoadingCounter()
                setDialog(false);
              });
            }} style={{
            backgroundColor: "#5dce7b",
            borderRadius: "20px",
            color: 'white',
            marginBottom: "10px",
            marginLeft: "auto",
            marginRight: "auto",
            marginLeft: "220px",
            marginTop: "20px"
          }}>Yes</Button>

        </div>
      </ReactDialogBox>} */}
      <div className={CommentClose ? 'w70 div1' : 'w95 div1'}>
        <DeleteWarningModal />
        <div style={{ display: 'flex', height: window.innerHeight - 110, alignItems: 'center', }}>
          <ArrowBackIos style={{ color: '#fff', cursor: 'pointer' }} onClick={() => { CurrentIndex > 0 && setCurrentIndex(CurrentIndex - 1) }} />
          {
            fileType === "image" ?
              <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column',  paddingTop: '2%' }}>
                <span className="imageGalleryName" style={{ marginBottom: '10px', color: '#fff', }}> <img src={ImageIcon} style={{ height: '20px' }} />  {data[CurrentIndex]?.name}</span>
                <img src={data[CurrentIndex]?.url} style={{ alignSelf: 'center', width: "95%", height: "80%", margin: '0 auto', objectFit: 'contain', maxHeight: '90%', maxWidth: '100%' }} />
              </div>
              : fileType === "video" ?
                <ReactPlayer url={data[CurrentIndex]?.url} controls={true} config={{ file: { attributes: { controlsList: 'nodownload', onContextMenu: e => e.preventDefault() } } }} width={'100%'} height={'70%'} />
                : fileType === "audio" ?
                  <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', backgroundColor: "#F9F6F1" }}>
                    <img src={HeadPhoneImage} style={{ width: '8rem', height: '8rem', margin: '8rem 0rem' }} />
                    <ReactPlayer url={data[CurrentIndex]?.url} controls={true} config={{ file: { attributes: { controlsList: 'nodownload', onContextMenu: e => e.preventDefault() } } }} width={'100%'} height={'100px'} />
                  </div>
                  : <></>
          }
          <ArrowForwardIos style={{ color: '#fff', cursor: 'pointer', marginLeft: 'auto' }} onClick={() => { CurrentIndex < data.length - 1 && setCurrentIndex(CurrentIndex + 1) }} />
        </div>
        <div className="client-popup-btns"
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: "auto",
          }}
        >
          <Button
            onClick={handleUpdateImportantStatus}
            style={{
              display: "flex",
              alignItems: "center",
              textTransform: "capitalize",
              background:isImportant ? "#424200" : "#111319",
              borderRadius:"10px"
            }}
          >
            <p style={{ fontFamily: "Nunito", marginBottom: '0px' }}>{isImportant ? 'Marked' : 'Mark'} As Important</p>
            {
              isImportant ? <Star style={{ color: "yellow", marginLeft: "10px" }} /> : <StarBorder style={{ color: "yellow", marginLeft: "10px" }} />
            }
          </Button>
          <Button
            onClick={handleUpdateApprovalStatus}
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "10px",
              textTransform: "capitalize",
              background:isApproved ? "#034803": "#111319",
              borderRadius:"10px"
            }}
          >
            <p style={{ fontFamily: "Nunito", marginBottom: '0px' }}>{isApproved ? 'Approved' : 'Approve'}</p>
            {
              isApproved ? <CheckCircle style={{ ...styles.icon, color: "green" }} /> : <CircleOutlined style={{ ...styles.icon, color: "green" }} />
            }
          </Button>
          <Button
            style={{
              display: "flex",
              alignItems: "center",
              textTransform: "capitalize",
              marginLeft: "10px",
              background:"#111319",
              borderRadius:"10px",
              position:"relative"
            }}
            onClick={() => {
              setCommentClose(true);
            }}
          >
            <p style={{ fontFamily: "Nunito", marginBottom: '0px' }}>Comments</p>
            <ChatBubbleOutline style={{ ...styles.icon }} />
            {allComments.length > 0 ? <span className="commnetNumbers" style={{}}>{allComments.length}</span> : " "}
          </Button>
          <Button
            style={{
              display: "flex",
              alignItems: "center",
              textTransform: "capitalize",
              marginLeft: "10px",
              background:"#2c0b0b",
              borderRadius:"10px"
            }}
            onClick={() => setDialog(true)}
          >
            <p style={{ fontFamily: "Nunito", marginBottom: '0px' }}>Delete</p>
            <DeleteOutline
              onClick={() => setDialog(true)}
              style={{ ...styles.icon }}
            />
          </Button>
        </div>
      </div>
      {
        CommentClose ? (
          <div className="div2 client-comments-main" style={{ height: window.innerHeight }}>
          
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                margin: " 20px 10px",
                alignItems: "center",
                width: "90%",
              }}
            >
              <p style={{ color: "white" }}>Comments</p>
              <Close
                style={{ color: "white" }}
                onClick={() => {
                  setCommentClose(false);
                }}
              />
            </div>
            <div
              style={{
                overflow: "auto",
                overflowX: "hidden",
                width: "90%",
                height: "100%",
              }}
            >
              {allComments?.map((item) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderRadius: "10px",
                    width: "90%",
                    wordWrap: "break-word",
                    margin: "5px",
                  }}
                >
                  <img
                    src={require("../../assets/images/chat.png")}
                    style={{ height: "30px", width: "30px" }}
                  />
                  <div style={{ marginLeft: "15px", padding:"10px", background:"#202668", borderRadius:"0 10px 10px 10px" }}>
                    <span style={{ color: "#83c8ff", fontSize: "12px", marginBottom: '5px', display:"block"}}>
                      {item.name}
                    </span>
                    <p
                      style={{
                        marginBottom: 5,
                        color: "#fff",
                        fontSize: "14px",
                        fontFamily: "Nunito",
                        fontWeight: "600",
                      }}
                    >
                      {" "}
                      {item.comment}
                    </p>
                    <p
                      style={{
                        marginTop: 5,
                        color: "#a5a5a5",
                        fontSize: "12px",
                        fontWeight: "normal",
                        fontFamily: "Nunito",
                      }}
                    >
                      Added : {moment(item?.createdAt).format("DD MMM YYYY")}
                    </p>
                  </div>
                </div>
              ))}
            </div>

            <textarea
              placeholder="Enter Your Comments Here ...."
              id="textArea"
              style={{
                borderRadius: "10px",
                width: "90%",
                marginTop: "auto",
                marginBottom: "20px",
                padding: "10px",
              }}
              rows={4} // Set the minimum number of visible lines
              value={text}
              onChange={handleTextChange}
            />
            <Button
              onClick={() => {
                dispatch(
                  addComment(
                    data[CurrentIndex].customerId,
                    data[CurrentIndex]._id,
                    text
                  )
                );
                setText('');
              }}
              style={{
                backgroundColor: "#5dce7b",
                borderRadius: "20px",
                color: "white",
                marginBottom: "10px",
                marginLeft: "auto",
                marginRight: "20px",
              }}
            >
              Add
            </Button>
          </div>
        )
          :
          <Close style={{ color: '#fff', position: 'absolute', top: '1.5rem', right: '1.5rem', cursor: 'pointer' }} onClick={handleClose} />
      }

    </div>
  );
}

export default Dashboard;
